import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useLocalStorage } from "@hooks/useLocalStorage";
import { DefaultTheme, ThemeProvider } from "styled-components/native";
import {
    DarkTheme,
    GrayTheme,
    LightTheme,
    Punk2Theme,
    PunkTheme,
    isMobile,
} from "../theme/constants";
import { useMedia } from "../theme/hooks/useMedia";

import { gql, useQuery } from "@hooks/useApollo";
import { LanguageCode, LanguageType, Languages } from "@hooks/useLanguage";
import { IPerformance } from "@screens/Trade/usePerformance";
import { MetaData, Pair, User, UserFe, Vault } from "gql/graphql";
import { Colors } from "../theme/constants";

import { AcceptTermsModal } from "@screens/Authentication/AcceptTerms";
import { ConnectModal } from "@screens/Authentication/Connect";
import { FORM_LOCAL_STORAGE_KEYS } from "@screens/Trade/components/Form/context";
import { ToastPosition } from "react-toastify/dist/types";
import { Price } from "utils";
import { useAccount } from "wagmi";

import { useWeb3Context } from "./web3";
import { useUser } from "./hooks/useUser";
import { useRouter } from 'next/router';

export enum Theme {
    DARK = "dark",
    PUNK = "punk",
    LIGHT = "light",
    GRAY = "gray",
    BLUE = "blue",
}
export const AVAILABLE_THEMES = [
    Theme.DARK,
    Theme.GRAY,
    // Theme.LIGHT,
    Theme.PUNK,
    Theme.BLUE,
];

interface IAppSettings {
    language: LanguageType;
    isSmartAccount?: boolean;
    hideBalance?: boolean;
    hideWallet?: boolean;
    trade: {
        showHistory: boolean;
        panelLeft: boolean;
        openOrderModal: boolean;
    };
    theme: {
        boxed: boolean;
        style: Theme;
        colorDeficiency: boolean;
    };
    notifications: {
        position: ToastPosition;
        closed: string[];
    };
    delegation?: boolean;
}

const AppContext = createContext(
    {} as {
        initial: {
            historicals: IPerformance[];
            prices: Price[];
            pairs: Pair[];
            pair?: Pair;
            vault?: Vault;
            user?: User;
            userFe: UserFe;
        };
        settings: IAppSettings;
        metadata: MetaData;
        pairs: Map<string, Pair>;
        newPairs: Pair[];
        theme: DefaultTheme;
        showConnectModal: boolean;
        userFE: UserFe | null;
        isCTAActive: boolean;
        showGetStarted: boolean;
        showTermsModal: boolean;
        refetchUser: () => Promise<void>;
        toggleTermsModal: () => void;
        toggleGetStarted: () => void;
        toggleConnectModal: () => void;
        toggleBoxedStyle: () => void;
        toggleTradeOpenOrderModal: () => void;
        toggleTradePanelLeft: () => void;
        toggleSmartAccount: () => void;
        toggleShowHistory: () => void;
        toggleBalance: () => void;
        toggleWallet: () => void;
        toggleColorVisionDeficiency: () => void;
        updateSetting: (newSettings: Partial<IAppSettings>) => void;
        resetAppSettings: () => void;
        onChangeTheme: (theme: Theme) => void;
        onChangeNotificationPosition: (position: ToastPosition) => void;
        addClosedNotification: (id: string) => void;
        userFELoading: boolean;
    }
);

const DEFAULT_APP_SETTINGS: IAppSettings = {
    isSmartAccount: false,
    hideBalance: false,
    hideWallet: false,
    language: Languages.get(LanguageCode.EN) as LanguageType,
    theme: {
        style: Theme.DARK,
        colorDeficiency: false,
        boxed: false,
    },
    trade: {
        showHistory: true,
        panelLeft: false,
        openOrderModal: true,
    },
    notifications: {
        position: "bottom-left",
        closed: [],
    },
    delegation: false
};

const useApp = (pageProps: any) => {
    const Media = useMedia();
    const { getKey, saveKey, deleteKey } = useLocalStorage();
    const { address } = useAccount();
    const {
        refetchUser,
        showGetStarted,
        userFE,
        userFELoading,
        toggleGetStarted,
    } = useUser({
        address,
    });
    const { isOtherNetwork } = useWeb3Context();
    const router = useRouter();

    const [isCTAActive, setIsCTAActive] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [settings, setSettings] =
        useState<IAppSettings>(DEFAULT_APP_SETTINGS);

    const [initialData, setInitialData] = useState({
        ...pageProps.initial,
        pairs: [],
        prices: [],
    });

    const { data } = useQuery(GET_PAIRS, {
        pollInterval: 60 * 1000,
        skip: !initialData?.pairs.length,
    });

    const metadata = useMemo(() => {
        return data?.metaData;
    }, [data?.metaData]);

    const pairs = useMemo(() => {
        const removePairs: string[] = [
            // "HOGS", "LE", "ZS"
        ];

        if (data?.pairs) {
            const pairsObject = new Map<string, Pair>();
            data?.pairs?.map((pair: Pair) => pairsObject.set(pair.from, pair));

            [...pairsObject.keys()].map((key) => {
                if (removePairs.includes(key.toUpperCase())) {
                    pairsObject.delete(key);
                }
            });

            return pairsObject;
        }

        const pairsObject = new Map<string, Pair>();
        pageProps?.initial?.pairs?.map((pair: Pair) => {
            pairsObject.set(pair.from, pair);
        });

        [...pairsObject.keys()].map((key) => {
            if (removePairs.includes(key.toUpperCase()))
                pairsObject.delete(key);
        });
        return pairsObject;
    }, [data?.pairs, pageProps]);

    const newPairs: Pair[] = useMemo(() => {
        let data: Pair[] = [];

        const newPairsArray: {
            from: string;
            to: string;
        }[] = [
                // { from: "SOL", to: "USD" }
            ];

        newPairsArray.map((pair) => {
            const foundPair = pairs?.get(pair.from);

            if (foundPair) data.push(foundPair);
        });
        return data;
    }, [pairs]);

    const theme = useMemo(() => {
        let newTheme: DefaultTheme = {
            ...DarkTheme,
            media: Media,
        };

        if (Theme.DARK === settings.theme.style)
            newTheme = { ...newTheme, ...DarkTheme };
        if (Theme.GRAY === settings.theme.style)
            newTheme = { ...newTheme, ...GrayTheme };
        if (Theme.PUNK === settings.theme.style)
            newTheme = { ...newTheme, ...PunkTheme };
        if (Theme.BLUE === settings.theme.style)
            newTheme = { ...newTheme, ...Punk2Theme };
        if (Theme.LIGHT === settings.theme.style)
            newTheme = { ...newTheme, ...LightTheme };

        if (settings.theme.colorDeficiency) {
            newTheme.color.red = "#f2b400";
            newTheme.color.green = "#0861ba";
        } else {
            newTheme.color.red = Colors.red;
            newTheme.color.green = Colors.green;
        }

        return newTheme;
    }, [Media, settings.theme]);

    const isRestricted = router?.query?.restricted;

    useEffect(() => {
        if (isOtherNetwork || isRestricted) {
            if (!isCTAActive) setIsCTAActive(true);
        } else {
            if (isCTAActive) setIsCTAActive(false);
        }
    }, [isCTAActive, isOtherNetwork, isRestricted]);

    useEffect(() => {
        const initData = async () => {
            try {
                const pairs = await fetch(`/api/pairs`, {
                    next: {
                        revalidate: 30,
                    },
                }).then((res) => res.json());

                if (pairs?.length) {
                    const prices = await fetch(
                        `/api/price?pairs=${pairs
                            .map((pair: Pair) => pair.feed)
                            .join(",")}`,
                        {
                            next: {
                                revalidate: 30,
                            },
                        }
                    ).then((res) => res.json());

                    setInitialData({
                        ...pageProps?.initial,
                        pairs,
                        prices,
                    });
                }
            } catch (err) {
                setInitialData({
                    ...pageProps?.initial,
                    pairs: [],
                    prices: [],
                });
            } finally {
                const storedSettings = await getKey("APP_SETTINGS");
                if (storedSettings)
                    setSettings({ ...DEFAULT_APP_SETTINGS, ...storedSettings });
            }
        };

        initData();
    }, [getKey, pageProps?.initial]);

    useEffect(() => {
        if (isMobile) return;
        if (typeof window !== "undefined") {
            document.documentElement.style.setProperty(
                "--black",
                theme.color.black
            );

            document.documentElement.style.setProperty(
                "--primary",
                theme.color.primary
            );
        }
    }, [theme.color.black, theme.color.primary]);

    const toggleConnectModal = useCallback(() => {
        setShowConnectModal(!showConnectModal);
    }, [showConnectModal]);

    const toggleTermsModal = useCallback(() => {
        setShowTermsModal(!showTermsModal);
    }, [showTermsModal]);

    const updateSetting = useCallback(
        async (newSettings: Partial<IAppSettings>) => {
            saveKey("APP_SETTINGS", { ...settings, ...newSettings });
            setSettings((settings) => ({ ...settings, ...newSettings }));
        },
        [saveKey, settings]
    );

    const addClosedNotification = useCallback(
        (id: string) => {
            updateSetting({
                ...settings,
                notifications: {
                    ...settings.notifications,
                    closed: [...settings.notifications.closed, id],
                },
            });
        },
        [settings, updateSetting]
    );

    const toggleShowHistory = useCallback(() => {
        updateSetting({
            trade: {
                ...settings.trade,
                showHistory: !settings?.trade?.showHistory,
            },
        });
    }, [settings, updateSetting]);

    const toggleBoxedStyle = useCallback(() => {
        updateSetting({
            theme: {
                ...settings.theme,
                boxed: !settings?.theme?.boxed,
            },
        });
    }, [settings.theme, updateSetting]);

    const toggleBalance = useCallback(() => {
        updateSetting({
            ...settings,
            hideBalance: !settings?.hideBalance,
        });
    }, [settings, updateSetting]);

    const toggleWallet = useCallback(() => {
        updateSetting({
            ...settings,
            hideWallet: !settings?.hideWallet,
        });
    }, [settings, updateSetting]);

    const toggleTradeOpenOrderModal = useCallback(() => {
        updateSetting({
            trade: {
                ...settings.trade,
                openOrderModal: !settings?.trade?.openOrderModal,
            },
        });
    }, [settings.trade, updateSetting]);

    const toggleTradePanelLeft = useCallback(() => {
        updateSetting({
            trade: {
                ...settings.trade,
                panelLeft: !settings?.trade?.panelLeft,
            },
        });
    }, [settings.trade, updateSetting]);

    const onChangeTheme = useCallback(
        (theme: Theme) => {
            updateSetting({
                theme: {
                    ...settings.theme,
                    style: theme,
                },
            });
        },
        [settings.theme, updateSetting]
    );

    const toggleSmartAccount = useCallback(() => {
        updateSetting({
            isSmartAccount: !settings.isSmartAccount,
        });
    }, [settings.isSmartAccount, updateSetting]);

    const onChangeNotificationPosition = useCallback(
        (position: ToastPosition) => {
            updateSetting({
                notifications: {
                    ...settings.notifications,
                    position,
                },
            });
        },
        [settings.notifications, updateSetting]
    );

    const toggleColorVisionDeficiency = useCallback(() => {
        updateSetting({
            theme: {
                ...settings.theme,
                colorDeficiency: !settings.theme.colorDeficiency,
            },
        });
    }, [settings.theme, updateSetting]);

    const resetAppSettings = useCallback(() => {
        setSettings(DEFAULT_APP_SETTINGS);
        deleteKey("APP_SETTINGS");
        deleteKey("FAVORITES");
        deleteKey("PanelGroup:sizes:PANEL_GROUP");
        deleteKey("HISTORY_SHOW_IN_TOKEN");
        deleteKey("HISTORY_SHOW_ALL_PAIRS");
        Object.values(FORM_LOCAL_STORAGE_KEYS).map((key) => deleteKey(key));
    }, [deleteKey]);

    return {
        initial: initialData,
        metadata,
        pairs,
        newPairs,
        settings,
        theme,
        userFE,
        showGetStarted,
        toggleGetStarted,
        refetchUser,
        addClosedNotification,
        updateSetting,
        onChangeNotificationPosition,
        toggleTradeOpenOrderModal,
        toggleShowHistory,
        toggleTradePanelLeft,
        toggleBoxedStyle,
        toggleColorVisionDeficiency,
        toggleSmartAccount,
        toggleBalance,
        toggleWallet,
        showConnectModal,
        showTermsModal,
        isCTAActive,
        toggleTermsModal,
        toggleConnectModal,
        resetAppSettings,
        onChangeTheme,
        userFELoading,
    };
};

export interface IAppProvider {
    children: JSX.Element | JSX.Element[];
    pageProps: any;
}

const AppProvider = ({ children, pageProps }: IAppProvider) => {
    const value = useApp(pageProps);

    return (
        <AppContext.Provider value={value}>
            <ThemeProvider theme={value.theme}>
                <ConnectModal />
                <AcceptTermsModal />
                {children}
            </ThemeProvider>
        </AppContext.Provider>
    );
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppProvider, useAppContext };

const GET_PAIRS = gql`
    query getPairs @api(name: subgraph) {
        pairs(first: 1000) {
            id
            from
            to
            feed
            spreadP
            volume
            accRollover
            accFundingLong
            accFundingShort
            curRollover
            curFundingLong
            curFundingShort
            makerMaxLeverage
            utilizationThresholdP
            lastFundingRate
            lastFundingVelocity
            maxFundingFeePerBlock
            lastFundingBlock
            lastFundingTime
            rolloverFeePerBlock
            lastRolloverBlock
            longOI
            shortOI
            maxOI
            maxLeverage
            tradeSizeRef
            group {
                id
                name
                maxLeverage
                minLeverage
                maxCollateralP
                longCollateral
                shortCollateral
            }
            makerFeeP
            takerFeeP
            usageFeeP
            fee {
                id
                minLevPos
                liqFeeP
                oracleFee
            }
        }
        metaData(id: "ostium") {
            maxTradesPerPair
            maxPendingMarketOrders
            totalTrades
            totalUsers
            totalOpenTrades
            totalVolume
        }
    }
`;
