export const Colors = {
    white: "#D0DBDA",
    black: "#070606",
    primary: "#FF5A19",
    background: "#111111",
    red: "#BC244A",
    yellow: "#FFC107",
    green: "#1A965D",
    vividGreen: '#00EE80',
    grey: "#d0dbda",
    orange: "#FF5A19",
    sand: "#493a34",
    darkSand: "#372A27",
    darkBrown: "#20110D",
    greyish: "#D0DBDAB2",
};
