import "raf/polyfill";
import "rc-slider/assets/index.css";
import "react-native-match-media-polyfill";
import "react-toastify/dist/ReactToastify.css";
import "./app.css";
import "./fonts.css";
// import whyDidYouRender from "@welldone-software/why-did-you-render";

// if (process.env.NODE_ENV === "development") {
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     });
// }

// @ts-ignore
if (process.browser) {
    // @ts-ignore
    window._frameTimestamp = null;
}

if (!Promise.allSettled) {
    const rejectHandler = (reason: any) => ({ status: "rejected", reason });

    const resolveHandler = (value: any) => ({ status: "fulfilled", value });

    Promise.allSettled = function (promises: any) {
        const convertedPromises = promises.map((p: any) =>
            Promise.resolve(p).then(resolveHandler, rejectHandler)
        );
        return Promise.all(convertedPromises);
    };
}

import type { AppProps } from "next/app";
import Head from "next/head";

const GoogleAnalytics = dynamic(
    () => import("nextjs-google-analytics").then((mod) => mod.GoogleAnalytics),
    {
        ssr: true,
    }
);
const Analytics = dynamic(
    () => import("@vercel/analytics/react").then((mod) => mod.Analytics),
    {
        ssr: true,
    }
);
const SpeedInsights = dynamic(
    () =>
        import("@vercel/speed-insights/next").then((mod) => mod.SpeedInsights),
    {
        ssr: true,
    }
);

import mixpanel from "mixpanel-browser";
import { WagmiProvider } from "@contexts/wagmi";
import { RootLayout } from "layouts";
import dynamic from "next/dynamic";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle``;
import Script from "next/script";
import { useEffect } from "react";

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
            mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
                track_pageview: true,
                persistence: "localStorage",
            });
        }
    }, []);
    return (
        <>
            <GlobalStyle />
            <GoogleAnalytics trackPageViews />
            <Head>
                <title>Ostium</title>
                {pageProps?.openGraphData?.map((og: any) => (
                    <meta {...og} key={og.key} />
                ))}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />

                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                />

                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    rel="manifest"
                    href="/manifest.json"
                    crossOrigin="use-credentials"
                />
            </Head>
            <Script src="/twitter-script.js" strategy="lazyOnload" />

            <WagmiProvider>
                <RootLayout pageProps={pageProps} Component={Component} />
            </WagmiProvider>
            <Analytics />
            <SpeedInsights />
        </>
    );
}

export default MyApp;
