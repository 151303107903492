import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { v4 as uuidV4 } from "uuid";

import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import { Text } from "@atoms/Text";
import { Button } from "@molecules/Button";
import { LineStatistic } from "@molecules/Statistic";
import { PairItem, PositionSizeCollateral } from "@organisms/List/Item";
import { formatPrice, getPairLogo, getPairName } from "@utils";

import { useRouting } from "expo-next-react-navigation";
import { Limit, Order, Pair, Trade } from "gql/graphql";
import dynamic from "next/dynamic";
import { Linking, Pressable } from "react-native";
import { toast } from "react-toastify";
import { ToastItem } from "react-toastify/dist/types";
import { useTheme } from "styled-components/native";
import { formatData } from "utils";
import { useAccount } from "wagmi";
import { useAppContext } from "./app";
import { useWeb3Context } from "./web3";
import Link from "next/link";

const Modal = dynamic(() => import("@organisms/Modal"));

export enum AppError {
    Error,
}

export type INotification = {
    id?: string;
    createdAt?: Date;
    title?: string;
    type?: "Trade" | "Error" | "Success" | "Info";
    description?: string;
    render?: JSX.Element | JSX.Element[];
    data?: {
        order?: Partial<Order>;
        trade?: Partial<Trade>;
        limit?: Partial<Limit>;
    };
    url?: string;
    hash?: string;
    autoClose?: number | false;
    closed?: boolean;
    onPress?: () => void;
    onClose?: () => void;
};

interface INotificationContext {
    notify: (notification: INotification, callback?: () => void) => void;
}

const NotificationContext = createContext({} as INotificationContext);

const useNotification = () => {
    const theme = useTheme();
    const { settings, addClosedNotification, newPairs } = useAppContext();
    const { address } = useAccount();
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const appNotifications: INotification[] = useMemo(() => {
        const newNotifications: INotification[] = newPairs?.map((pair) => {
            const id = `new-pair-${pair.feed}`;

            return {
                id,
                title: "New pair added",
                type: "Info",
                render: (
                    <NewPair
                        from={pair.from}
                        to={pair.to}
                        onClose={() => {
                            addClosedNotification(id);
                            toast.dismiss(id);
                        }}
                    />
                ),
                closed: false,
                autoClose: false,
                onClose: () => {
                    addClosedNotification(id);
                },
            };
        });

        const tradingContestNotifications: INotification[] = [
            {
                id: "trading-started",
                title: "Trading Contest Live",
                type: "Info",
                render: (
                    <Box
                        style={{
                            paddingHorizontal: theme.spacing.bigger,
                            paddingTop: theme.spacing.bigger,
                        }}
                    >
                        <Flex justify="space-between">
                            <Flex gap={theme.spacing.small} align="center">
                                <Text huge>🏆</Text>
                                <Flex
                                    gap={theme.spacing.smallest}
                                    direction="column"
                                >
                                    <Text bold>PRIZE POOL</Text>
                                    <Text
                                        small
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        20,000 USDC
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex align="center" gap={theme.spacing.smaller}>
                                <Link href="/contest">
                                    <Text
                                        smaller
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        Read more
                                    </Text>
                                </Link>
                                <Icon
                                    name="caret"
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                    size={10}
                                />
                            </Flex>
                        </Flex>
                    </Box>
                ),
                closed: false,
                autoClose: false,
                onClose: () => {
                    addClosedNotification("trading-started");
                },
            },
        ];
        // return newNotifications;
        return [...newNotifications, ...tradingContestNotifications];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addClosedNotification]);

    const notify = useCallback(
        async (notification: INotification) => {
            try {
                const id = notification.id || uuidV4();
                const newNotification: INotification = {
                    ...notification,
                    id,
                    createdAt: new Date(),
                    autoClose: notification?.hash
                        ? 5000
                        : notification.autoClose != null
                        ? notification.autoClose
                        : 5000,
                };

                const { trade, limit, order } = notification.data || {};

                const renderData = notification.data ? (
                    trade ? (
                        <Box>
                            <NotificationPosition
                                collateral={trade?.collateral}
                                leverage={trade?.leverage}
                                pair={trade.pair}
                                isBuy={trade.isBuy}
                                size={trade?.tradeNotional}
                                takeProfit={trade.takeProfitPrice}
                                stopLoss={trade?.stopLossPrice}
                            />
                        </Box>
                    ) : order ? (
                        <Box>
                            <NotificationPosition
                                collateral={order.collateral}
                                leverage={order.leverage}
                                pair={order.pair}
                                isBuy={order.isBuy}
                                size={order.tradeNotional}
                                // takeProfit={
                                //     order?.takeProfitPrice
                                //         ? Number(formatUnits(trade.takeProfitPrice, 18))
                                //         : undefined
                                // }
                                // stopLoss={
                                //     order?.stopLossPrice
                                //         ? Number(formatUnits(trade.stopLossPrice, 18))
                                //         : undefined
                                // }
                            />
                        </Box>
                    ) : limit ? (
                        <Box>
                            <NotificationPosition
                                collateral={limit.collateral}
                                leverage={limit.leverage}
                                pair={limit.pair}
                                isBuy={limit.isBuy}
                                size={limit.tradeNotional}
                                takeProfit={limit?.takeProfitPrice}
                                stopLoss={limit?.stopLossPrice}
                            />
                        </Box>
                    ) : undefined
                ) : (
                    notification?.render
                );

                toast(
                    <Notification {...newNotification} render={renderData} />,
                    {
                        autoClose: newNotification.autoClose,
                        toastId: newNotification.id,
                        updateId: newNotification.id,
                        progressStyle: {
                            bottom: 0,
                            backgroundColor:
                                newNotification.type === "Success"
                                    ? theme.color.green
                                    : newNotification.type === "Error"
                                    ? theme.color.red
                                    : theme.color.primary,
                        },
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },

        [theme.color.green, theme.color.primary, theme.color.red]
    );

    useEffect(() => {
        const unsubscribe = toast.onChange((payload: ToastItem) => {
            switch (payload.status) {
                case "added":
                    // new toast added
                    break;
                case "updated":
                    // toast updated
                    break;
                case "removed":
                    const foundAppNotification = appNotifications.find(
                        (item) => item.id === payload.id
                    );
                    if (
                        !settings.notifications.closed.includes(
                            payload.id as string
                        ) &&
                        foundAppNotification
                    ) {
                        foundAppNotification?.onClose?.();
                    }
                    break;
            }
        });
        return () => {
            unsubscribe();
        };
    }, [appNotifications, settings.notifications.closed]);

    // const { isConnected } = useNetInfo();

    useEffect(() => {
        const init = async () => {
            appNotifications.map((item) => {
                if (settings.notifications.closed.includes(item.id as string))
                    return;
                notify(item);
            });
        };

        init();
    }, [
        address,
        appNotifications,
        notify,
        setNotifications,
        settings.notifications.closed,
    ]);

    const onDelete = async (id: string) => {
        let newNotifications = [...notifications];
        const foundIndex = newNotifications.findIndex((old) => old.id === id);
        newNotifications.splice(foundIndex, 1);
        setNotifications(newNotifications);
    };

    return {
        notifications,
        notify,
        onDelete,
    };
};

export interface INotificationProvider {
    children: ReactNode;
}

const NotificationProvider = ({ children }: INotificationProvider) => {
    const value = useNotification();

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};

const useNotificationContext = () => useContext(NotificationContext);

export { NotificationContext, NotificationProvider, useNotificationContext };

const NewPair = ({
    from,
    to,
    onClose,
}: {
    from: string;
    to: string;
    onClose: () => void;
}) => {
    const theme = useTheme();
    const { navigate } = useRouting();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const name = getPairName(from);

    const openTrade = useCallback(() => {
        navigate({
            routeName: "/",
            params: {
                from,
                to,
            },
        });
        setTimeout(() => {
            setIsModalVisible(false);
            onClose();
        }, 1000);
    }, [from, navigate, onClose, to]);

    const openModal = useCallback(() => {
        setIsModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsModalVisible(false);
        onClose();
    }, [onClose]);

    return (
        <Pressable onPress={openModal}>
            <Box
                style={{
                    paddingHorizontal: theme.spacing.bigger,
                    paddingTop: theme.spacing.bigger,
                }}
            >
                <Flex justify="space-between">
                    <Flex gap={theme.spacing.smaller} align="center">
                        <Image
                            source={getPairLogo(from)}
                            priority
                            width={32}
                            height={32}
                            alt={`${name} logo"`}
                        />
                        <Box>
                            <Text bold>
                                {from}/{to}
                            </Text>
                            <Text
                                small
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                {name}
                            </Text>
                        </Box>
                    </Flex>
                    <Flex align="center" gap={theme.spacing.smaller}>
                        <Text
                            smaller
                            color={theme.color.rgba(theme.color.white, 0.6)}
                        >
                            Read more
                        </Text>
                        <Icon
                            name="caret"
                            color={theme.color.rgba(theme.color.white, 0.6)}
                            size={10}
                        />
                    </Flex>
                </Flex>

                <Modal
                    title={`New pair added: ${name}`}
                    isVisible={isModalVisible}
                    style={{
                        maxWidth: 320,
                    }}
                    onClose={closeModal}
                >
                    <Image
                        source={`/assets/announcement-${name.toLowerCase()}.jpeg`}
                        priority
                        width={320}
                        height={320}
                        alt={`/announcement-${name.toLowerCase()}.jpeg`}
                    />
                    <Box
                        style={{
                            padding: theme.spacing.bigger,
                        }}
                        gap={theme.spacing.bigger}
                    >
                        <Text small>
                            {name} is now available for trading on Ostium.
                        </Text>
                        <Button
                            primary
                            text={`Trade ${name}`}
                            onPress={openTrade}
                        />
                    </Box>
                </Modal>
            </Box>
        </Pressable>
    );
};

const NotificationPosition = ({
    isBuy,
    leverage,
    pair,
    collateral,
    size,
    takeProfit,
    stopLoss,
}: {
    leverage: bigint;
    collateral: bigint;
    size: bigint;
    isBuy?: boolean;
    pair?: Pair;
    stopLoss?: bigint;
    takeProfit?: bigint;
}) => {
    const theme = useTheme();

    const formatedData = formatData({
        collateral: collateral?.toString(),
        stopLossPrice: stopLoss?.toString(),
        takeProfitPrice: takeProfit?.toString(),
        tradeNotional: size?.toString(),
    });

    return (
        <Box
            style={{
                paddingHorizontal: theme.spacing.bigger,
                paddingVertical: theme.spacing.big,
                paddingBottom: 0,
            }}
            gap={theme.spacing.small}
        >
            <Flex justify="space-between">
                <PairItem isBuy={isBuy} leverage={leverage} pair={pair} />
                <PositionSizeCollateral
                    pair={pair}
                    collateral={formatedData.collateral}
                    size={formatedData.tradeNotional}
                    style={{
                        alignItems: "flex-end",
                    }}
                />
            </Flex>

            {/* <Box gap={theme.spacing.tiny}> */}
            {/* <LineStatistic
                                label="Open price"
                                value={formatPrice(trade.openPrice, {
                                    significant: 6,
                                    currency: true,
                                })}
                            /> */}
            {/* </Box> */}
            {takeProfit || stopLoss ? (
                <Box gap={theme.spacing.smallest}>
                    {takeProfit ? (
                        <LineStatistic
                            label="Take Profit"
                            green
                            value={formatPrice(formatedData.takeProfitPrice, {
                                currency: true,
                                significant: 6,
                            })}
                        />
                    ) : null}

                    {stopLoss ? (
                        <LineStatistic
                            red
                            label="Stop Loss"
                            value={formatPrice(formatedData.stopLossPrice, {
                                currency: true,
                                significant: 6,
                            })}
                        />
                    ) : null}
                </Box>
            ) : null}
        </Box>
    );
};

const Notification = ({
    render,
    type,
    title,
    description,
    createdAt,
    url,
    hash,
    id,
    onClose,
    onPress,
}: INotification) => {
    const theme = useTheme();
    const { provider } = useWeb3Context();

    const onNotificationPress = () => {
        if (url) {
            Linking.openURL(
                // @ts-ignore
                url
            );
            return;
        }

        onPress?.();
    };

    // useEffect(() => {
    //     const init = async () => {
    //         if (!hash) return;
    //         const tx = await provider.waitForTransaction(hash, 5);
    //         console.warn(tx);

    //         if (tx) {
    //             const status = tx.status ? "Success" : "Error";
    //             const message = tx.status
    //                 ? "Transaction confirmed"
    //                 : "Transaction failed";

    //             if (id)
    //                 toast.update(id, {
    //                     render: <Notification title={message} type={status} />,
    //                 });
    //             // notify({
    //             //     title: message,
    //             //     description: `Transaction hash: ${hash}`,
    //             //     type: status,
    //             // });
    //         }
    //     };

    //     init();
    // }, [hash, id, provider]);

    return (
        <Pressable onPress={onNotificationPress}>
            <Box
                style={{
                    height: "100%",
                    position: "relative",
                    borderRadius: theme.radius.medium,
                    overflow: "hidden",
                    backgroundColor: theme.color.rgba(theme.color.black, 1),
                }}
            >
                <Box
                    style={{
                        borderWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.1),
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            0.2
                        ),
                        borderRadius: theme.radius.medium,
                        paddingVertical: theme.spacing.bigger,
                        paddingBottom: theme.spacing.bigger,
                    }}
                >
                    {title ? (
                        <Box
                            justify="center"
                            style={{
                                paddingHorizontal: theme.spacing.bigger,
                            }}
                        >
                            <Text bold color={theme.color.white}>
                                {title}
                            </Text>
                        </Box>
                    ) : null}

                    {render ? (
                        render
                    ) : description ? (
                        <Box
                            justify="center"
                            style={{
                                paddingHorizontal: theme.spacing.bigger,
                                paddingTop: theme.spacing.small,
                            }}
                        >
                            <Text
                                color={theme.color.rgba(theme.color.white, 0.7)}
                                smaller
                                lineHeight={theme.text.small}
                            >
                                {description}
                            </Text>
                        </Box>
                    ) : null}

                    {
                        // hash ? (
                        //     <Flex
                        //         justify="space-between"
                        //         style={{
                        //             paddingHorizontal: theme.spacing.bigger,
                        //             paddingTop: theme.spacing.big,
                        //         }}
                        //     >
                        //         {/* <Text smallest color={rgba(theme.color.white, 0.4)}>
                        //         {formatDate(createdAt as Date)}
                        //     </Text> */}
                        //         <Text smallest mono bold primary>
                        //             {`${hash?.substring(0, 6)}`}
                        //         </Text>
                        //     </Flex>
                        // ) : null
                    }
                </Box>
            </Box>
        </Pressable>
    );
};
